<template>
  <article class="container-base local-root" :class="{loading}">
    <section class="breadcrumb">
      <router-link :to="{ name: 'Home' }" class="breadcrumbT">
        <img src="@/assets/BreadcrumbHome.png" alt="Home" class="breadcrumbImg">
      </router-link>
      <img src="@/assets/IconeSeta.png" alt="Home" class="iconImg">
      <router-link :to="{ name: 'OndeEstamos' }" class="breadcrumbT">
        <img src="@/assets/listaBreadcrumb.png" alt="Lista" class="breadcrumbImg">
      </router-link>
      <img src="@/assets/IconeSeta.png" alt="Home" class="iconImg">
      <h1>PROJETO</h1>
    </section>
   
    <h2 class="local-nome">
      <transition name="crossfade" mode="out-in">
        <span :key="loading" :class="{loading}"><b>{{ !loading ? local.nome : 'Hospital Geral "Dr. José Pangela" de Vila Penteado' }}</b></span>
      </transition>
    </h2>
    <div class="local-detalhes">
      <div class="local-foto-wrapper">
        <img
          class="local-foto"
          v-lazy="!loading ? cms.getAssetUrl(local.foto.private_hash, {w: 900, h: 900, f: 'contain', q: 90}) : null"
          :data-srcset="!loading ? `
            ${cms.getAssetUrl(local.foto.private_hash, {w: 450, h: 450, f: 'contain', q: 90})} 450w,
            ${cms.getAssetUrl(local.foto.private_hash, {w: 650, h: 650, f: 'contain', q: 90})} 650w,
            ${cms.getAssetUrl(local.foto.private_hash, {w: 800, h: 800, f: 'contain', q: 90})} 800w,
            ${cms.getAssetUrl(local.foto.private_hash, {w: 1200, h: 1200, f: 'contain', q: 90})} 1200w,
            ${cms.getAssetUrl(local.foto.private_hash, {w: 1640, h: 1640, f: 'contain', q: 90})} 1640w,
            ${cms.getAssetUrl(local.foto.private_hash, {w: 1800, h: 1800, f: 'contain', q: 90})} 1800w,
          ` : null"
        >
      </div>
      <div class="local-info">
        <div v-if="local.encerrado" class="toast">
          Projeto Encerrado
        </div>
        <p>
          <span>Cidade</span><br>
          <transition name="crossfade" mode="out-in">
            <span :key="loading" :class="{loading}"><b>{{ !loading ? local.cidade : 'São Paulo - SP' }}</b></span>
          </transition>
        </p>
        <p>
          <span>Plano de trabalho</span><br>
          <transition name="crossfade" mode="out-in">
            <span :key="loading" :class="{loading}"><b>{{ !loading ? local.plano_de_trabalho : 'Gestão' }}</b></span>
          </transition>
          <br>
          <transition name="crossfade" mode="out-in">
            <a
              class="download-plano"
              v-if="!loading && local.plano_de_trabalho_doc"
              :href="local.plano_de_trabalho_doc.data.full_url"
              target="_blank"
            >
              <inline-svg :src="require('@/assets/ic_download.svg')" />
              <span> PDF</span>
            </a>
            <span v-else />
          </transition>
        </p>
        <p>
          <span>Serviço</span><br>
          <transition name="crossfade" mode="out-in">
            <span :key="loading" :class="{loading}"><b>{{ !loading ? local.servico : 'Pronto Socorro UTI NeoNatal 10 Leitos' }}</b></span>
          </transition>
        </p>
        
        <router-link
          class="button verde"
          :to="{name: 'TransparenciaCovid-19', query:{projectName: this.id}}"
          v-if="routerCovid"
        >
          Acessar Transparência
        </router-link>

        <router-link
          class="button verde"
          :to="{name: 'TransparenciaProjetos', query:{projectName: this.id}}"
          v-if="routerProjetos"
        >
          Acessar Transparência
        </router-link>
      </div>
      <div class="local-historia">
        <h3>História</h3>
        <transition name="crossfade" mode="out-in">
          <div v-if="!loading" key="texto" class="local-historia-corpo" v-html="local.historia" />
          <div v-else key="loading" class="local-historia-corpo loading">
            <p><span>Lorem {{ Array(6).fill('ipsum dolor sit amet').join(' ') }}</span></p>
            <p><span>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, commodo vitae, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. Donec non enim in turpis pulvinar facilisis. Ut felis. Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus</span></p>
            <p><span>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</span></p>
          </div>
        </transition>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>

  .button{
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 15px 0 8px 0;
    padding: 12px 0;
  }

   h1{
    display: block;
    margin: 3px 0 0 9px;
    font-size: 25px;
  }

  .breadcrumb{
    display: flex;
  }

  .breadcrumbImg{
    margin: 6px 9px 0 9px;
    width: 25px;
    height: 25px;
  }

  .iconImg{
    margin: 13px 9px 0 9px;
    width: 6px;
    height: 10px;
  }
 
  @media (max-width: 430px) {

    .button{
      font-size: 13px;
    }

    .iconImg{
      margin: 13px 9px 0 9px;
      width: 4px;
      height: 8px;
    }

    .breadcrumbImg{
      margin: 6px 9px 0 9px;
      width: 20px;
      height: 20px;
    }

    h1{
      display: block;
      margin: 5px 0 0 9px;
      font-size: 18px;
    }
  }

  article {
    margin-bottom: 18px;
  }

  .local-nome {
    span.loading {
      color: transparent;
      background-color: #31425F43;
      white-space: pre-wrap;

      animation: pulse 2s infinite alternate linear both;
    }
  }

  .local-detalhes {
    display: grid;

    column-gap: 20px;
    grid-template-columns: 320px 1fr;

    .toast {
      padding: 6px 8px;
      z-index: 1;
      background: var(--ahbb-green);
      color: var(--ahbb-blue);
      text-align: center;
      text-transform: uppercase;
    }

    .local-foto-wrapper {

      grid-row: 1;
      grid-column: 1 / -1;

      width: 100%;
      height: 440px;

      background-color: #293B592b;
   
      .local-foto {
        width: 100%;
        height: 100%;

        object-fit: cover;
        object-position: center;
       
        transition: opacity .15s ease-in-out;
      }
    }

    .local-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-row: 2;
      grid-column: 1;

      padding: 16px;

      background: var(--ahbb-blue-gradient);
      color: white;

      p {
        font-size: 17px;
        color: white;
        width: 100%;
      }

      span {
        animation: none;

        &.loading {
          color: transparent;
          background-color: #fff2;

          animation: pulse 2s infinite alternate linear both;
        }
      }
     
      .download-plano {
        display: inline-block;
       
        padding: 4px;
        margin-left: -4px;
       
        border-radius: 50vh;

        font-size: 17px;
        color: var(--ahbb-green);
        text-decoration: none;

        transition: color .2s ease;

        &:hover {
          color: var(--ahbb-green-light-2);

          /deep/ svg {
            path, rect {
              fill: var(--ahbb-green-light-2);
            }
          }
        }

        /deep/ svg {
          display: inline;
          vertical-align: middle;
          height: 17px;
          width: 12px;

          path, rect {
            fill: var(--ahbb-green);
            transition: fill .2s ease;
          }
        }

        span {
          vertical-align: middle;
        }
      }
    }

    .local-historia {
      grid-row: 2 / span 2;
      grid-column: 2;

      padding: 16px;

      .local-historia-corpo.loading /deep/ span {
        animation: pulse 2s infinite alternate linear both;
        color: transparent;
        background-color: #31425F43;
      }
    }

    @media (max-width: 1020px) {
      grid-template-columns: 1fr;

      .local-historia {
        grid-row: 3;
        grid-column: 1;
      }
    }
  }

  .crossfade-enter-active, .crossfade-leave-active {
    animation: none !important;
    transition: opacity .15s ease-in-out !important;
  }

  .crossfade-enter, .crossfade-leave-to {
    opacity: 0;
  }
</style>

<script>
import cms from '@/lib/cms.js'
import { SimpleSVG as InlineSvg } from 'vue-simple-svg'

export default {
  name: 'OndeEstamosDetalhe',
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    loading: false,
    local: {
      nome: '',
      cidade: '',
      plano_de_trabalho: '',
      servico: '',
      historia: '',
      foto: {
        data: {
          full_url: '',
        },
      },
    },
    cms,
    cacheLocais: {},
    routerProjetos: null,
    routerCovid: null,
  }),
  methods: {
    async load() {
      this.loading = true
      this.fotoLoaded = false
     
      if (this.id in this.cacheLocais) {
        this.local = this.cacheLocais[this.id]

        if (this.local.covid19) {
          this.routerCovid = true
          this.routerProjetos = false
        }
        else {
          this.routerProjetos = true
          this.routerCovid = false
        }
      }
      else {
        this.local = (await cms.getItem('onde_estamos', this.id, { fields: '*,foto.private_hash,plano_de_trabalho_doc.data.full_url' })).data
        this.cacheLocais[this.id] = this.local

        if (this.local.covid19) {
          this.routerCovid = true
          this.routerProjetos = false
        }
        else {
          this.routerProjetos = true
          this.routerCovid = false
        }
      }

      this.loading = false
    },
    
  },
  watch: {
    id(to, from) {
      this.load()
    },
  },
  async created() {
    await this.load()
  },
  components: {
    InlineSvg,
  },
  metaInfo() {
    return {
      title: (!this.loading && this.local) ? `${this.local.nome}` : 'Onde Estamos',
      meta: [
        { property: 'og:image', vmid: 'og:image', content: this.local?.foto ? cms.getAssetUrl(this.local?.foto?.private_hash, { w: 900, h: 900, f: 'contain', q: 90 }) : undefined },
        { property: 'twitter:image', vmid: 'twitter:image', content: this.local?.foto ? cms.getAssetUrl(this.local?.foto?.private_hash, { w: 900, h: 900, f: 'contain', q: 90 }) : undefined },
      ],
    }
  },
}
</script>
